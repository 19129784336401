/* eslint-disable no-case-declarations */
import React, { useEffect, useReducer, useMemo, useRef, useState } from 'react';
import _, { startCase } from 'lodash';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import classNames from 'classnames';
import { debounce } from 'debounce';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import client from '../feathers';
import TMDataTable from '../lib/Table/TMDataTable';
import Containers from './Containers';
import ChartCanvas from './ChartCanvas';
import PincodeDialog from './PincodeDialog';
import useAuth from '../hooks/useAuth';
import useIntl from '../hooks/useIntl';
import DownloadButtons from './DownloadButtons';
import useDrawer from '../hooks/useDrawer';
import { FilterForm } from './FilterForm';
import useTermsDialog from '../hooks/useTermsDialog';
import { useSnackbar } from 'notistack';
import SplitButton from '../components/SplitButton';
import TransferRevokeDialog from './TransferRevokeDialog';
import BlockDialog from './BlockDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { useAlertDialog } from '../context/AlertDialogProvider';
import BlockReport from './BlockReport';
import useConfirmDialog from '../hooks/useConfirmDialog';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useBills } from '../hooks/useBills';
import useContainersOptions from '../hooks/useContainersOptions';
import ReleasesDriversAndBarges from './releases-drivers-and-barges/ReleasesDriversAndBarges';
import useTransferRevoke from '../hooks/useTransferRevoke'; 
import useLocalFilter from '../hooks/useLocalFilter';
import { removeSpecialCharacters } from '../util/release';
import useSentry from '../hooks/useSentry';
import NMoTDialog from './NMoT/NMoTDialog';
import { types as NMoTTypes } from './NMoT/types';
import AuditTrailDialog from './AuditTrailDialog';
import { getConnectedOrganizations } from '../util/connections';
import { formatDate } from '../util/date';
import TabsUI from './TabsUI';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GreenLightsPopover from './GreenLightsPopover';
import { findOrgName } from '../util/connections';
import Chip from '@material-ui/core/Chip';
import TMDialog from '../lib/Dialog/TMDialog';
import { getFormFields } from '../util/release';
import SRPForm from './SRPForm';
import { useTerminals } from '../hooks/useTerminals';
import { useCmaAgents } from './carrier-ui/hooks/useCmaAgents';
import useCarrierApiError from './carrier-ui/hooks/useCarrierApiError';
import { isTokenValid } from '../util/token';
import useWalletError from '../hooks/useWalletError';
import { useHistory } from 'react-router-dom';

const socketio = require("socket.io-client");
const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  canvaspie: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  canvasStyleSm : {
    left:'0',
    top:'15px',
    position:'absolute',
  },
  canvasStyleLg : {
    left:'27%',
    top:'0',
    position:'absolute',
  },
  heading_div: {
    backgroundColor:'#fff', 
    position: 'relative'
  },
  content: {
    margin: 0
  },
  showSearchText: {
    margin:'-40px 0 0 0',
    fontWeight:500, 
    fontSize:'1rem',
    position:'relative', 
    minHeight:'44px',
    overflow:'auto',
    backgroundColor:"#fff", 
    zIndex:'1200',
    marginLeft:'20px',
    top:'60px', 
    width:'57%'
  }
}));
const ReleaseOverview = (props) => 
{
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_LIMIT':
        return { ...state, limit: action.payload }

      case 'UNSET_BL_NUMBER':
        return { ...state, pincodeBlNumber: null, showPincode: true }

      case 'CHANGE_PAGE':
        // also close all open rows
        return { ...state, skip: action.payload, rowsExpanded: [] }

      case 'SET_HIDDEN_COLUMNS':
        return { ...state, hiddenColumns: action.payload }

      case 'SET_COLUMNS':
        return { ...state, columns: action.payload }

      case 'SEARCH':
        // also close all open rows
        return { ...state, skip: 0, search: action.payload, rowsExpanded: [] }

      case 'SET_FILTER':
        return { ...state, skip: 0, filter: JSON.stringify(action.payload), filterIndex: Math.random() }
      
      case 'SET_ROWS_EXPANDED':
        return { ...state, rowsExpanded: action.payload }

      case 'SET_ANON_ADDRESSES':
        return { ...state, anonAddresses: action.payload }

      case 'SORT':
        // also close all open rows
        const { sortColumn, sortDirection } = action.payload
        const columns = state.columns.map(column => {
          const { sortDirection: direction, ...options } = column.options || {}
          return { ...column, options: { ...options, ...column.name === sortColumn ? { sortDirection: sortDirection === 'ascending' ? 'asc' : 'desc' } : undefined } }
        })
        return { ...state, sortColumn, sortDirection: sortDirection === 'ascending' ? 1 : -1, columns, rowsExpanded: [] }

      case 'SET':
        return { ...state, ...action.payload }
      
      case 'SET_DRIVERS_AND_BARGES':
        return { ...state, driversAndBarges: { ...action.payload } };

      case 'SET_LOCAL_FILTER': 
        return {...state, local: action.payload}

      case 'REFRESH_BILLS': 
        const { doRefreshBills, showBackdrop } = action.payload
        return {...state, doRefreshBills, showBackdrop: showBackdrop ? showBackdrop : true}
      
      case 'SET_CONTAINERS_OPTIONS_STATE':
        return {...state, containersOptionStateByBl: action.payload}

      case NMoTTypes.SHOW_NMoT:
        return {...state, NMoT: {dialog: true}}
      
      case NMoTTypes.CANCEL_NMoT:
        return {...state, NMoT: {selected: '', dialog: false}}
      
      case NMoTTypes.SET_NMoT:
        return {...state, NMoT: {...state.NMoT, selected: action.payload}}
      
      case 'RESET':
        return {...state, editFormData: {...action.payload}}

      default:
        return { ...state }
    }
  }
  const editFormData = {
    portOfLoading: '',
    portOfDestination: '',
    vessel: '',
    stayNumber: '',
    lloydsNumber: '',
    voyageNumber: '',
    pickupLocation: '',
    turnInLocation: '',
    validUntil: '',
    turnInReference: ''
}
  const initialState = {
    total: undefined,
    skip: 0,
    limit: 10,
    data: [],
    containersByBl: {},
    containersOptionStateByBl: {},
    rowsExpanded: [],
    pincodeBlNumber: null,
    showPincode: true,
    columns: [],
    hiddenColumns: ['portOfLoading', 'portOfDestination'],
    search: '',
    sortColumn: 'blNumber',
    sortDirection: 1,
    filter: JSON.stringify({valid: 'showOnlyValid'}),
    local: {
      filter: {}
    },
    filterIndex: 0,
    termsAccepted: true,
    showTransferRevokeDialog: false,
    opType: 'transfer', //transfer or revoke,
    showBlockDialog: false,
    blockStatus: false, // true=block, false=unblock
    blockBlNumber: '',
    showAuditTrailDialog: false,
    selectedReleaseAddresses: [],
    anonAddresses: [],
    connectedOrganizations: [],
    connections: [],
    driversAndBarges: {
      dialog: false,
      type: 'assign'
    },
    doRefreshBills: true,
    showBackdrop: true,
    NMoT: {
      dialog: false,
      selected: ''
    },
    doGrouping: true,
    showSelected: false,
    rowsSelected:[],
    containersToGetPincode:[],
    disableTabs: false,
    editFormData: Object.assign({}, editFormData),
  }
  
  const querySearch = new URLSearchParams(props.location.search).get('bl');

  const rowsExpanded = querySearch ? [0] : [];
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    search: querySearch,
    rowsExpanded
  });

  const { translate } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showComponent, closeDrawer } = useDrawer();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const addressesRef = useRef({});
  const classes = useStyles();
  const { user } = useAuth();
  const [showDashboard, setShowDashboard] = useState(!user.features.canDashboard);
  const [containersFlatView, setContainersInFlatView] = useState([]); //all selected containers on flat view with full data
  const [selectedContainerAddress, setContainersAddresses] = useState([]); //addresses of selected containers on flat view
  const { bills: billsOfLading, anonAddresses } = useBills(state.filter, state.doRefreshBills, state.showBackdrop, state.doGrouping);
  const containersOptionStateByBl = useContainersOptions(state.containersByBl, containersFlatView, state.doGrouping);
  const logSentry = useSentry();
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [submitEditLabel, setSubmitEditLabel] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const isCarrier = user.organizationRole === 'carrier';
  const [terminals] = useTerminals();
  const [cmaAgents] = useCmaAgents();
  const onCarrierApiError = useCarrierApiError();
  const onWalletError = useWalletError();
  const history = useHistory();
  
//open edit dialog
  const openEditRelease = async () => {
    setOpenEdit(true);
    dispatch({type: 'SET', payload: { editFormData: editFormData}});
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'RESET', payload: { ...state.editFormData, [name]: value } });
  };
  //Edit multiple containers for flat view
  const handleEditRelease = async () => {
    showProgressDialog();
    let _editFormData = Object.assign({}, state.editFormData);

    try {
      for (const key in _editFormData) {
        if (_editFormData[key] === '') {
          delete _editFormData[key];
        }
       if (![undefined, null, ''].includes(_editFormData.validUntil)) {
            _editFormData.validUntil = _editFormData.validUntil.split("/").reverse().join("-");
        }
        if (![undefined, null, ''].includes(_editFormData.validFrom)) {
            _editFormData.validFrom = _editFormData.validFrom.split("/").reverse().join("-");
        }
      }
      await client.service('releases').patch(
        null, 
        _editFormData,
        {
          query: { address: { $in: selectedContainerAddress }}
        }
      );
      hideProgressDialog();
      setOpenEdit(false);
      dispatch({type: 'SET', payload: { editFormData: editFormData}});
      refreshBills(true, false);
    } catch(e) {
      hideProgressDialog();
      onCarrierApiError(e);
    }
  }

  const refreshBills = (doRefreshBills = true, showBackdrop = true) => {
    dispatch({type: 'REFRESH_BILLS', payload: { doRefreshBills, showBackdrop } })
    // remove any selected containers in memory
    // this is probably due a crossover with the selection in containerview
    // selections are kept in memory, but not shown on the screen, so it's confusing, thinking you are for instance revoking an entire BL
    // but in memory only one or a few are 'selected' and thus only one or a few are revoked
    addressesRef.current = {}
    const rowsExpanded = state.rowsExpanded;
    dispatch({ type: 'SET', payload: { selectedReleaseAddresses: [], setContainersAddresses: [], rowsSelected: [], rowsExpanded: [] } })
    dispatch({ type: 'SET', payload: { rowsExpanded } })
  }

  const { onTransferRevoke } = useTransferRevoke(state.selectedReleaseAddresses, refreshBills, state.anonAddresses, dispatch)

  const applyLocalFilter = useLocalFilter(state.doGrouping, state.showSelected);
  
  const onOk = async () => {
    try {
      await client.service('users').patch(user.id, { termsAcceptedAt: new Date() })
    } catch (e) {
      //ignore for SCRP-164
    } finally {
      window.location.reload()
    }
  }

  const getFilters = (state) => {
    const { containerNumber, pickupLocation, turnInLocation, releaseStatus, valid, transferStatus } = JSON.parse(state.filter)
    return {
      containerNumber, pickupLocation, turnInLocation, releaseStatus, valid, transferStatus
    }
  }

  const onFetchPincode = showPincode => async (pincode) => {
    const selectedReleaseAddresses = state.doGrouping ? await getSelectedReleaseAddresses(pincode) : await getSelectedContainerAddress();
    const pincodeBlNumber = state.doGrouping ? pincode : '';
    if (user.features.canNmot && showPincode) {
      dispatch({ type: NMoTTypes.SHOW_NMoT })
    }
    let containersToGetPincode;
    if(!state.doGrouping){
      containersToGetPincode = state.data.filter(r => selectedContainerAddress.includes(r.address)).map(opt => opt);
    }
    dispatch({ type: 'SET', payload: { pincodeBlNumber, showPincode, selectedReleaseAddresses, containersToGetPincode } })
  }

  const { showConfirmDialog } = useConfirmDialog(
    'pincode.confirm.title', 'pincode.confirm.text', 
    onFetchPincode(true), onFetchPincode(false),
    'getpincode.fetch.confirm', 'getpincode.fetch.skip',
    true
  )

  const onBlock = async (blockStatus, blockBlNumber) => {
    const blockAction = blockStatus ? 'block' : 'unblock'
    dispatch({ type: 'SET', payload: { showBlockDialog: false } })
    
    if (user.organizationRole !== 'carrier') {
      return;
    }

    let results = [];
    let error;
    var addresses = state.doGrouping ? state.selectedReleaseAddresses: selectedContainerAddress
    try {
      showProgressDialog();
      for (const address of addresses) {
        results.push(await client.service('releases').patch(
          address, 
          { type: blockAction },
          // { query: { address: { $in: addresses }} }
        ));
      }
    } catch (e) {
      logSentry(e)
      error = e.message
    } finally {
      hideProgressDialog();
      refreshBills(true, false);

      const successes = results.filter(r => !r.error)
      const errors = results.filter(r => r.error)
      let content;
      if (error) {
        try {
          content = translate('general.error.detail', { error: translate(`backend.error.${error}`) })
        } catch (err) {
          logSentry(error)
          content = `${translate('general.error')}: ${error}`
        }
      } else {
        content = <BlockReport data={{successes, errors}} type={blockAction} />
      }
      showAlert(translate(`blockReport.${blockAction}.summary`), content);
    }
  }

  const { showDialog } = useTermsDialog({ onOk })
 
  const tableColumns = [
    {name: 'containers', options: {display: false,  viewColumns: false, filter: false, print: false}},
    { name: 'blNumber'},
    { name: 'portOfLoading' },
    { name: 'portOfDestination' },
    { name: 'vessel' },
    { name: 'agent' },
    { name: 'stayNumber' },
    { name: 'lloydsNumber' },
    { name: 'voyageNumber' },
    { name: '', label: '', options: { viewColumns: false, filter: false, print: false }},
    ].map(column => ({ ...column, ...column.name && column.name !== 'containers' ? { label: translate(`releaseOverview.columns.${column.name}`) } : undefined }));
  
    //columns for flat table view
    const flatTableColumns = [
      { name: translate('releaseOverview.containers.columns.releaseStatus')},
      { name: translate('releaseOverview.containers.columns.containerNumber')},
      { name: translate('releaseOverview.columns.vessel')},
      { name: translate('releaseOverview.columns.blNumber') },
      { name: translate('releaseOverview.containers.columns.validUntil')},
      { name: translate('releaseOverview.containers.columns.pickupLocation')},
      { name: translate('releaseOverview.containers.columns.turnInLocation')},
      { name: translate('releaseOverview.containers.columns.turnInReference')},
      { name: translate('releaseOverview.containers.columns.receivedFrom')},
      { name: translate('releaseOverview.containers.columns.transferStatus')},
      { name: 'Transferred to'}
    ].map(column => ({ ...column, ...column.name && column.name !== 'containers' ? { label: column.name } : undefined }));
    
  const getSelectedReleaseAddresses = async (blNumber) => {
    const selectedReleaseAddresses = addressesRef.current[blNumber];
    if (selectedReleaseAddresses?.length) {
      return selectedReleaseAddresses.map(r => r.releaseAddress);
    }
    const releasesAddresses = state.containersByBl[blNumber].map(({releaseAddress}) => releaseAddress);

    return releasesAddresses;
  }
  //get selected containers from flat view table
  const getSelectedContainerAddress = async() => {
    if(selectedContainerAddress?.length){
      return selectedContainerAddress.map(release => release)
    }
  }
  const transferRevokeOnClick = async (bill, opType) => {
    const addresses = state.doGrouping ? await getSelectedReleaseAddresses(bill.blNumber) : await getSelectedContainerAddress();
    dispatch({ type: 'SET', payload: { showTransferRevokeDialog : true, opType, selectedReleaseAddresses: addresses } })
  }

  const blockOnClick = async (bill, blockStatus) => {
    if (user.organizationRole === 'carrier') {
      const addresses = state.doGrouping ? await getSelectedReleaseAddresses(bill.blNumber) : await getSelectedContainerAddress();
      dispatch({ type: 'SET', payload: { showBlockDialog: true, blockStatus, blockBlNumber: bill.blNumber, selectedReleaseAddresses: addresses } })
    }
  }

  const assignOnClick = async ({ bill, type }) => {
    const addresses = state.doGrouping ? await getSelectedReleaseAddresses(bill.blNumber) : await getSelectedContainerAddress();
    dispatch({ type: 'SET', payload: { driversAndBarges: { dialog: true, type }, selectedReleaseAddresses: addresses } })
    // comment in order to copy behaviour of other '*OnClick' methods
    // dispatch({ type: 'SET_DRIVERS_AND_BARGES', payload: { dialog: true, type } });
  }

  const getAuditTrailOnClick = async (bill) => {
    const addresses = state.doGrouping ? await getSelectedReleaseAddresses(bill.blNumber) : await getSelectedContainerAddress()
    dispatch({ type: 'SET', payload: { showAuditTrailDialog: true, selectedReleaseAddresses: addresses } })
  }

  useEffect(() => {
    var _bills;
    var filterSelectedReleases;
    // billsOfLading is undefined at first, instead of an empty array, to be able to determine whether we are still loading the screen, or 
    // dealing with an empty resultset AFTER the main query (or search) returned nothing.
    if (billsOfLading) {
      if(!state.doGrouping && state.showSelected) {
         filterSelectedReleases  = billsOfLading.filter(function (release) {
          return selectedContainerAddress.includes(release.address)
        });
        _bills = applyLocalFilter(filterSelectedReleases, state.local.filter, state.doGrouping);
        dispatch({ type: "SET", payload: { data:_bills, total: _bills?.length || 0 }});
        
      } else {
        _bills = applyLocalFilter(billsOfLading, state.local.filter, state.doGrouping);
	// SCRDEV-1211: doRefreshBills was default set to true, so after the first load, set it back to false
        dispatch({ type: "SET", payload: { 
		data: _bills, 
		doRefreshBills: false,
		containersByBl: groupBy(_bills, 'blNumber'), 
		total: _bills?.length || 0 
	}})
      }
    }
    // dispatch({type: 'REFRESH_BILLS', payload: { doRefreshBills: false } });
  }, [billsOfLading, state.local, state.doRefreshBills, state.doGrouping  ])

  useEffect(() => {
    // only call 'handleRequestForSelectedReleases' when we're in the "view per container"
    !state.doGrouping && handleRequestForSelectedReleases();
  } , [state.showSelected, billsOfLading, selectedContainerAddress])

  //to show selected releases
  const handleRequestForSelectedReleases = () => {
    var selected;
    var _bills;
   
    if (state.showSelected) {
      selected =  billsOfLading.filter((release) => {return selectedContainerAddress?.length && selectedContainerAddress.includes(release.address)});
      _bills = applyLocalFilter(selected, state.local.filter, false);
      setTimeout(() => {
        setContainersInFlatView(selected);
      }, 1500)
    } else {
      selected =  billsOfLading.map((release) => {return selectedContainerAddress?.length && selectedContainerAddress.includes(release.address)});
      var select =  billsOfLading.filter((release) => {return selectedContainerAddress?.length && selectedContainerAddress.includes(release.address)});
      _bills = applyLocalFilter(billsOfLading, state.local.filter, false);
      setTimeout(() => {
        setContainersInFlatView(select);
      }, 1500)
    }
    const indice = selected.reduce((out, bool, index) => bool ? out.concat(index) : out, [])
    dispatch({ type: "SET", payload: { data:_bills, rowsSelected : indice, total: _bills?.length || 0 }});
  }

  //to enable disable submit label while editing multiple containers
  useEffect(() => {
    if(Object.values(state.editFormData).every(x => x === null || x === ''|| x === undefined)){
      setSubmitEditLabel(false);
    }else {
      setSubmitEditLabel(true);
    }
  } , [state.editFormData])

  useEffect(() => {
    dispatch({ type: 'SET_ANON_ADDRESSES', payload: anonAddresses })
  } , [anonAddresses])

  useEffect(() => {
    dispatch({ type: 'SET_CONTAINERS_OPTIONS_STATE', payload: containersOptionStateByBl })
  } , [containersOptionStateByBl])
  
  // Apply hiddenColumn preferences
  useEffect(() => { 
    handlePreferences() 
  }, [])

  useEffect(() => {
    const columns = state.doGrouping ?
      tableColumns.map(c => state.hiddenColumns.includes(c.name) ? ({ ...c, options: { ...c.options, display: 'false' } }) : ({ ...c })) : 
      flatTableColumns.map(c => state.hiddenColumns.includes(c.name) ? ({ ...c, options: { ...c.options, display: 'false' } }) : ({ ...c }))

    dispatch({ type: 'SET_COLUMNS', payload: columns })
  }, [state.hiddenColumns, state.doGrouping])

  const handlePreferences = async () => {
    const preferences = await client.service('preferences').find()
    dispatch({ type: 'SET_HIDDEN_COLUMNS', payload: preferences.hiddenColumns })
  }

  // Handle termsAccepted dialog
  useEffect(() => {
    const { termsAcceptedAt, invitedByMsc } = JSON.parse(window.sessionStorage.getItem('org')) || { termsAcceptedAt: null }
    dispatch({ type: "SET", payload: { termsAccepted: invitedByMsc ? !!termsAcceptedAt : true } })
  }, [])

  useEffect(() => {
    showDialog(!state.termsAccepted)
  }, [state.termsAccepted])

  // Handle connected organizations
  useEffect(() => { 
    handleConnectedOrganizations()
  }, [])

  // auto-refresh with socket
  useEffect(() => { 

console.log("useffect new socket");

//    const clientSocket = socketio(process.env.REACT_APP_BACKEND_URL_SOCKETIO);
    const action = key => (
      <React.Fragment>
        <Button 
           size="small" 
           variant="outlined" 
           style={{color:'#fff',border:'1px solid #fff', marginRight:'10px'}}
           onClick={() => refreshPage()}
        >
          { translate('button.refresh') }
        </Button>
        <Button 
           size="small" 
           variant="outlined"
           style={{color:'#fff',border:'1px solid #fff'}}
           onClick={() => closeSnackbar()}
        >
         { translate('button.dismiss') }
        </Button>
      </React.Fragment>
    );
    
    const isContainOrganization = (address) => {
      return state.anonAddresses.includes(address)
    }
    
//    clientSocket.on("release-notification", (arg) => {

console.log(client)
console.log(client.io)

    client.io.on("release-notification", (arg) => {
      if(!isCarrier && arg.type==='transfer' && isContainOrganization(arg.payload.targetOrganizationAddress))
      {
        enqueueSnackbar(
          `${translate('notification.message')}`, {
            variant: 'success',
            persist: true,
            action,
            style:{maxWidth:'420px'}
          }
        );
      }
    })
  }, [state.anonAddresses])


  const refreshPage = async() => {   
    showProgressDialog();
    closeSnackbar();
    setTimeout(() =>  {
      refreshBills();
      hideProgressDialog();
    }, 2000);
  }
 
  const handleConnectedOrganizations = async () => {
    if (!isTokenValid()) {
      onWalletError(new Error(translate('session.expired')));
      history.push('/');
      return;
    }
    const connections = await window.walletApi.getConnections();
    const connectedOrganizations = await getConnectedOrganizations();
    dispatch({ type: 'SET', payload: { connections, connectedOrganizations } })
  }
   
  const onDriversAndBargesClose = () => {
    dispatch({
      type: 'SET_DRIVERS_AND_BARGES',
      payload: { dialog: false, type: state.driversAndBarges.type },
    });
  }

  const groupBy = (data, key) => {
    if (!data?.length) return {}

    return data.reduce((acc, c) => {
      const groupKey = c[key];
      let filteredObj = c.containers || [];

      acc[groupKey]
        ? acc[groupKey].push(...filteredObj)
        : (acc[groupKey] = [...filteredObj]);

      return acc;
    }, {});
  }

  const getActions = bill => {
    const isCarrier = user.organizationRole === 'carrier';
    const blank = '';
    return <SplitButton 
              disabledBtn={state.doGrouping ? false : !showActionBtn} 
              key={state.doGrouping && bill.blNumber} options={[{
              label: translate('blActions.transfer'),
              onClick: () => { transferRevokeOnClick(state.doGrouping ? bill : '', 'transfer'); window.addToDataLayer('action-btn', 'transfer'); },
              disabled: state.doGrouping ? ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl[bill.blNumber]?.transfer) : 
                        ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl?.transfer)
            }, {
              label: translate('blActions.revoke'),
              onClick: () => { transferRevokeOnClick(state.doGrouping ? bill : '', 'revoke'); window.addToDataLayer('action-btn', 'revoke'); },
              disabled: state.doGrouping ? ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl[bill.blNumber]?.revoke) : 
                        ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl?.revoke)
            }, !isCarrier ? {} : {
              label: translate('blActions.block'),
              onClick: () => { blockOnClick(state.doGrouping ? bill : '', true); window.addToDataLayer('action-btn', 'block'); },
              disabled: state.doGrouping ? (!state.containersOptionStateByBl[bill.blNumber]?.block) : (!state.containersOptionStateByBl?.block),
            }, !isCarrier ? {} : {
              label: translate('blActions.unblock'),
              onClick: () => { blockOnClick(state.doGrouping ? bill : '', false); window.addToDataLayer('action-btn', 'unblock'); },
              disabled: state.doGrouping ? (!state.containersOptionStateByBl[bill.blNumber]?.unblock) : (!state.containersOptionStateByBl?.unblock),
            }, isCarrier ? {} :  {
              label: translate('blActions.pincode'),
              onClick: () => {
              if (!user.features.canTransferRevoke) { onFetchPincode(true)(state.doGrouping ? bill.blNumber : '') }
              else { showConfirmDialog(state.doGrouping ? bill.blNumber : '') }
              window.addToDataLayer('action-btn', 'pincode');
              },
              disabled: state.doGrouping ? (!state.containersOptionStateByBl[bill.blNumber]?.pincode) : (!state.containersOptionStateByBl?.pincode),
            }, isCarrier ? {} : {
                label: translate('blActions.assign'),
                onClick: () => { assignOnClick(state.doGrouping ? { bill, type: 'assign' } : { blank, type: 'assign' }); window.addToDataLayer('action-btn', 'assign'); },
		    disabled: (!user.features.canAssignDriver && !user.features.canAssignBarge) ||
                            (state.doGrouping ? !state.containersOptionStateByBl[bill.blNumber]?.assign : !state.containersOptionStateByBl?.assign),
            }, isCarrier ? {} : {
                label: translate('blActions.un_assign'),
                onClick: () => { assignOnClick(state.doGrouping ? { bill, type: 'un_assign' }: { blank, type: 'un_assign' }); window.addToDataLayer('action-btn', 'unassign'); },
		    disabled: (!user.features.canAssignDriver && !user.features.canAssignBarge) ||
                            (state.doGrouping ? !state.containersOptionStateByBl[bill.blNumber]?.unassign : !state.containersOptionStateByBl?.unassign),
            }, {
                label: translate('blActions.audittrail'),
                onClick: () => { getAuditTrailOnClick(state.doGrouping ? bill : ''); window.addToDataLayer('action-btn', 'getAuditTrail'); },
                className: 'showPremium',
              },isCarrier && !state.doGrouping ? 
              {
                label: 'Edit',
                onClick: () => { openEditRelease(); },
              }: {}].filter(o => !!o.label)
            } />
  }

  const getData = (bills) => {
    return bills.map(bill => [bill.containers, bill.blNumber, bill.portOfLoading, bill.portOfDestination, bill.vessel, bill.agent, bill.stayNumber, bill.lloydsNumber, bill.voyageNumber,
    getActions(bill), bill.id]) // skipped options don't have labels, filter them out
  }

  //get data to show flat view
  const getFlatViewData = (bills) => {
    return bills.map(bill => [getStatus(bill), bill.containerNumber, bill.vessel, bill.blNumber, formatDate(bill.validUntil), bill.pickupLocation, bill.turnInLocation,
      bill.turnInReference, bill.agent, getTransferStatus(bill), transferTo(bill), bill.id
      ]) // skipped options don't have labels, filter them out
  }

  const getStatus =(bill) => {
    if(bill && bill.releaseStatus){
        if(user.features.canGreenLights){
          return <GreenLightsPopover data={bill.greenLights} text={translate(`releaseStatus.${bill.releaseStatus}`)}/>
        }
        else{
          return translate(`releaseStatus.${bill.releaseStatus}`)
        }
    } else {
	  return '';
    }
  }

  const transferTo = (bill) => {
    let nextParty = "";
    if (bill.transferStatus === "transferred") {
      nextParty = <Chip label="Go Premium" color="primary" size="small" clickable onClick={() => setOpenPremiumDialog(true)}/>
    
      if (user.features.canSeeTransferInfo) { 
        if (bill.nextParty) {
          if (bill.nextParty.startsWith('address:')) {
            nextParty = findOrgName(bill.nextParty.replace('address:', ''), state.connections, state.connectedOrganizations)
          } else {
            nextParty = bill.nextParty;
          }
        } else {
          nextParty = translate('transferStatus.toNextParty');
        }
      }
    }
    return nextParty;
  }
  const getTransferStatus = (bill) => {
    if(bill && bill.transferStatus){
    let transferStatus = translate(
      bill.transferStatus === 'assigned' ? `transferStatus.assigned.info` : `transferStatus.${bill.transferStatus}`, 
      {
        type: bill.assignedDriverName ? translate('driver.title') : translate('barge.title'),  // in case of 'assigned'
        name: bill.assignedDriverName ? bill.assignedDriverName : bill.assignedBargeName // in case of 'assigned'
      }
    );
    return transferStatus;
    }else return '';
  }
  const onFilterClick = () => {
    setShowDashboard(false);
    showComponent(
      <FilterForm
        onFilterChange={onFilterChange}
        filter={{...state.local.filter ,...JSON.parse(state.filter)}}
        onClose={showAll}
      />,
      translate('dataTable.controls.filterTable')
    );
  };
  const showAll = () => {
    onFilterChange({valid: 'showOnlyValid'});
    onDrawerClose();
    // clear the table title
    dispatch({ type: 'SEARCH', payload: '' });
    dispatch({ type: 'SET', payload: { searchMessage: '', searching: false } });
  };
  
  const handleCanvasClick = (dataPoint, chartId, label) =>  {
    // console.debug("dataPoint", dataPoint)
    // console.debug("label", label)
    const dataPoints = { 
      valid: 'showOnlyValid', 
      expired: 'showOnlyInvalid',
      expiresIn1: 'expiresIn1', 
      expiresIn5: 'expiresIn5' 
    };
    // This needs to be in sync with the dashboard query and FilterForm (labels in initialState)
    const chartToFilter = {
      '0': 'releaseStatus',
      '1': 'transferStatus',
      '2': 'pickupLocation',
      '3': 'valid',
      '4': 'portOfDestination', // TODO double-check
    };

    // close the open bills
    dispatch({ type: 'SET_ROWS_EXPANDED', payload: [] })
    dispatch({ type: 'SET', payload: { searching: true } });

    // if a validity is picked, use that. otherwise use 'showBoth', since it does so too in the chart
    let filter = {
      valid: ['valid', 'expired', 'expiresIn1', 'expiresIn5'].includes(dataPoint) ? dataPoints[dataPoint] : 'showBoth'
    };
    let labels = { 
      valid: translate(`valid.${filter.valid}`) 
    };
    
    // Don't overwrite 'valid' - it was set here above
    if (chartId !== '3') {
      // releaseStatus (0) needs to be passed as an array
      filter[chartToFilter[chartId]] = (chartId > 0 ? dataPoint : [dataPoint]);
      labels[chartToFilter[chartId]] = label;
    }
    
    onFilterChange(filter, labels);
  }

  const onFilterChange = async (filter, labels={}) => {
    // console.debug("filter", filter)
    // console.debug("labels", labels)

    if (_.isEmpty(labels)) {
      labels = Object.assign({}, filter);
    }

    let searchMessage = [];
    const {valid, ...localFilter} = filter;
    
    // Check if the 'valid' filter value has changed
    // If so, a new search is launched to the backend
    if (!_.isMatch(JSON.parse(state.filter), {valid})) {
      dispatch({ type: 'SET_FILTER', payload: _.pick(filter, ['valid'])})
    }
    searchMessage.push(`${translate('filter.field.valid')}: ${translate(`valid.${filter.valid}`).toLowerCase()}`);

    // set local filter
    dispatch({ type: 'SET_LOCAL_FILTER', payload: { filter: localFilter } });

    // set table title - filter out empty filter values
    if (!_.isEmpty(localFilter)) {
      searchMessage.push(
        ...Object.keys(_.pickBy(localFilter, (f => f.length > 0)))
           .map(f => translate(`filter.field.${f}`) + `: ${labels[f]}`)
      );
    }
    searchMessage = searchMessage.join(', ');
    dispatch({ type: 'SET', payload: { searchMessage, searching: true } });
  };

  const onTransferClose = () => {
    dispatch({ type: 'SET', payload: { showTransferRevokeDialog: false } });
  } 
  const onBlockClose = () => dispatch({ type: 'SET', payload: { showBlockDialog: false } })
  const onAuditTrailClose = () => dispatch({ type: 'SET', payload: { showAuditTrailDialog: false } })
  const onDrawerClose = () => closeDrawer();

  const containerFilters = getFilters(state);

  //select dropdown to switch views
  const handleSelectView = (doGrouping) => {
    showProgressDialog();
    dispatch({ type: 'SET', payload: { doGrouping, showSelected: false, rowsExpanded: [], rowsSelected: [], doRefreshBills: true, showBackdrop: false }})
    setContainersAddresses([]);
    setContainersInFlatView([]);
    setShowActionBtn(false);
    // reset filter & search
    showAll();
    setTimeout(() => {
      hideProgressDialog();
      dispatch({ type: 'SET', payload: { disableTabs: false }})
    }, 2500);
  }

  const handleCheck = async() => {
   dispatch({ type: 'SET', payload: { showSelected : !state.showSelected }})
  }

  const table = useMemo(() => 
    <div className="no-radius-table">
      <div className={classNames(classes.heading_div)}>
          <TabsUI options={[
            { 
              label: translate('releasesOverview.title'), 
              disabled:state.disableTabs,
              handleSelectView: () => { handleSelectView(true)}
            },
            {
              label: translate('flatView.title'), 
              disabled:state.disableTabs,
              handleSelectView: () => { handleSelectView(false)},
              className:'iconClass'
            }
          ]}/>
           {!state.doGrouping && user.features.canContainerView && <div className='actionsTabView'>
            <FormGroup style={{display:'inline-block'}}>
                        <FormControlLabel 
                          control={
                            <Checkbox  
                              checked={state.showSelected}
                              color='primary'
                              onChange={() => handleCheck()}
                              />} 
                          label={ translate('show selected containers')} />
            </FormGroup>
          <div style={{width:'auto', display:'inline-block'}}>{getActions()}</div>
        </div>}
        {state.searching
            ? (!user.features.canContainerView && !state.doGrouping ? '' : 
                <h6 className={classNames('showSearchText', classes.showSearchText)} >
                  Showing results for {state.searchMessage}&nbsp;
                  <Button color="secondary" size="small" variant="outlined" onClick={showAll}>{translate('filter.showAll')}</Button>
                </h6>
              )
            : ''}
      </div>
      {!user.features.canContainerView && !state.doGrouping
          ?
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '475px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-view-by-container" />
        :
      <TMDataTable
        className={!state.doGrouping && 'flatViewTable' }
        title={''}
        columns={state.columns}
        data={state.doGrouping ? getData(state.data): getFlatViewData(state.data)}
        options={{
          rowsPerPage: state.doGrouping ? 10 : 500,
          expandableRows: state.doGrouping ? true : false,
          expandableRowsOnClick: state.doGrouping ? true: false,
          selectableRows: state.doGrouping ? 'none': 'multiple',
          rowsExpanded: state.rowsExpanded,
          rowsPerPageOptions: [10, 50, 150, 300, 500, 1000, 5000, state.data.length>5000 ? state.data.length: ''],
          searchText: state.search,
          search: state.searching ? false : true, //hide search icon
          searchOpen: state.searching ? false : true,
          searchAlwaysOpen: true, 
          selectableRowsOnClick: true,
          rowsSelected: state.rowsSelected,
          download: false,
          filter: false,
          print: false,
          textLabels: {
            body: {
              noMatch: state.total === undefined 
                ? translate('dataTable.controls.loading') 
                : (state.total === 0 ? translate('dataTable.controls.noMatch') : translate('dataTable.controls.loading'))
            },
            pagination: {
             rowsPerPage: translate('dataTable.controls.rowsPerPage')
            },
          },
          onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            dispatch({ type: 'SET', payload: { rowsSelected } })
            var selected = state.data.filter((row, i) => rowsSelected.includes(i));
            if(rowsSelected.length>0){
              setShowActionBtn(true);
            }else{
              setShowActionBtn(false);
            }
            //get address of all selected ones
            const selectedAddresses = selected.map((release) => {
              { return release.address}
            })
            setContainersAddresses(selectedAddresses);
            setTimeout(() => {
              setContainersInFlatView(selected);
            }, 1500)
          },
          customToolbar: () => 
            <DownloadButtons 
              onFilterClick={onFilterClick} 
              filter={{...JSON.parse(state.filter), ...state.local.filter}}
              search={state.search} 
              refreshBills={refreshBills}
              doTransfer={onTransferRevoke}
              anonAddresses={state.anonAddresses}
            />,
          onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                dispatch({ type: 'CHANGE_PAGE', payload: tableState.page * state.limit });
                break;

              case 'columnViewChange':
                const hiddenColumns = tableState.columns.filter(c => c.display === 'false').map(c => c.name);
                dispatch({ type: 'SET_HIDDEN_COLUMNS', payload: hiddenColumns });
                client.service('preferences').create({ hiddenColumns });
                break;
  
              default:
              return;
            }
          },
          onChangeRowsPerPage: limit => dispatch({ type: "SET_LIMIT", payload: limit }),
          onColumnSortChange: (sortColumn, sortDirection) => dispatch({ type: 'SORT', payload: { sortColumn, sortDirection } }),
          customSearch: (searchQuery, currentRow, columns) => {
            const find = val => removeSpecialCharacters(val.toString().toLowerCase()).indexOf(removeSpecialCharacters(searchQuery.toLowerCase())) >= 0
         
            let isFound = false;
            let nestedFound = false;
            const [innerRow] = currentRow;

            for(const val of currentRow) {
              const matchExists = find(val||'');
              if (matchExists) {
                isFound = true;
                break;
              }
            }
          
            state.doGrouping && Array.isArray(innerRow) && innerRow.forEach(obj => {
              for(const val of Object.values(obj)) {
                if(val) {
                  const matchExists = find(val||'');
                  if (matchExists) {
                    nestedFound = true;
                    break;
                  }
                }
              }
            })
            dispatch({ type: "SET", payload: { total: (isFound || nestedFound) ? 1 : 0 } });

            return isFound || nestedFound;
         
          },
          onSearchChange: debounce(text => dispatch({ type: 'SEARCH', payload: text }), 500),
          // Track which rows have been expanded, so we can expand them again after doRefreshBills.
          // Unless search is active, then collapse all rows in case doRefreshBills excludes a BL from the search,
          // because otherwise the table is showing an empty BL (does not match the search, but is still marked as 'expanded')
          onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
            const payload = allRowsExpanded.map(r => r.index)
            !state.search && dispatch({ type: 'SET_ROWS_EXPANDED', payload })
          },
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1
            const [containers, blNumber] = rowData
            return (
              <TableRow>
                <TableCell colSpan={colSpan} style={{backgroundColor: '#A89571', padding: '2em 0'}}>
                  <Containers
                    containers={containers}
                    refreshBills={refreshBills} 
                    onContainersSelect={selectedReleases => {  addressesRef.current[blNumber] = selectedReleases }} 
                    connectedOrganizations={state.connectedOrganizations}
                    connections={state.connections}
                  />
                </TableCell>
              </TableRow>
            );
          }
        }}
      />}
      
      </div>, [state.data, billsOfLading, state.rowsExpanded, state.containersOptionStateByBl, state.doGrouping, showActionBtn, state.search, state.disableTabs]
  )
  return (
    <React.Fragment>
    { 
      <Accordion expanded={showDashboard} onChange={() => {
        if (!showDashboard) { onDrawerClose(); }
        setShowDashboard(!showDashboard);
        window.addToDataLayer('dashboard', showDashboard ? 'open' : 'close')
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="dashboard-content"
          id="dashboard-header"
          classes={{ content: classes.content}}
          style={{margin:'0', backgroundColor: '#ddd', flexDirection: 'row-reverse', padding: 0, height: '50px',minHeight:'42px', borderBottom:'4px solid rgba(112,146,184, 0.8)'}}
        >
          <h3 className="showPremium">{translate(`dashboard.show.${showDashboard}`)}</h3>
        </AccordionSummary>
        <AccordionDetails style={{padding: '0'}}>
          <div className={classNames('charts', classes.canvaspie)}>
            { user.features.canDashboard
              ?
                <ChartCanvas showDashboard={showDashboard} anonAddresses={state.anonAddresses} handleCanvasClick={handleCanvasClick}/>
              :
                <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '275px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-dashboard" />
            }
          </div>
        </AccordionDetails>
      </Accordion>
    }
     { table }
     
      <PincodeDialog
        show={state.pincodeBlNumber!==null}  
        blNumber={state.pincodeBlNumber} 
        containers={state.doGrouping ? (state.containersByBl[state.pincodeBlNumber] || []) : state.containersToGetPincode} 
        selectedReleaseAddresses={state.doGrouping ? state.selectedReleaseAddresses : selectedContainerAddress}
        showPincode={state.showPincode} 
        handleDialogClose={() => {
          dispatch({ type: "UNSET_BL_NUMBER" });
          refreshBills();
        }} 
        anonAddresses={state.anonAddresses}
        refreshBills={refreshBills}
        connectedOrganizations={state.connectedOrganizations}
        connections={state.connections}
      />
      
      <TransferRevokeDialog 
        show={state.showTransferRevokeDialog} 
        type={state.opType} 
        onTransfer={ async org => { 
          await onTransferRevoke('transfer', org, (state.doGrouping ? state.selectedReleaseAddresses : selectedContainerAddress));
          if (user.features.canNmot) {
            dispatch({ type: NMoTTypes.SHOW_NMoT });
          }
        }}  
        onRevoke={ async () => {
          await onTransferRevoke('revoke');
        }}
        onCancel={onTransferClose} 
        selectedReleaseAddresses={state.selectedReleaseAddresses} 
      />
      
      <BlockDialog
        show={state.showBlockDialog} 
        blockStatus={state.blockStatus} 
        blockBlNumber={state.blockBlNumber}
        onBlock={onBlock} 
        onCancel={onBlockClose} 
        selectedReleaseAddresses={state.selectedReleaseAddresses}
      />

      <AuditTrailDialog
        show={state.showAuditTrailDialog} 
        onCancel={onAuditTrailClose} 
        selectedReleaseAddresses={state.selectedReleaseAddresses}
        anonAddresses={state.anonAddresses}
        connections={state.connections}
      />

      <ReleasesDriversAndBarges
        driversAndBarges={state.driversAndBarges}
        onCancel={onDriversAndBargesClose}
        selectedReleaseAddresses={state.selectedReleaseAddresses}
        refreshBills={refreshBills}
        anonAddresses={state.anonAddresses}
      />

      <NMoTDialog 
        show={state.NMoT.dialog}
        selected={state.NMoT.selected}
        selectedReleaseAddresses={state.selectedReleaseAddresses} 
        onSelection={(selected) => { 
          dispatch({type: NMoTTypes.SET_NMoT, payload: selected})  
        }} 
        // onCancel={() => dispatch({type: NMoTTypes.CANCEL_NMoT})}
        onCancel={(event, reason) => { if (!['escapeKeyDown', 'backdropClick'].includes(reason)) { dispatch({type: NMoTTypes.CANCEL_NMoT}) } }}
      />
     <TMDialog
        key="premium-transfer-info"
        title={translate('premium.transferinfo.title')}
        dialogOpen={openPremiumDialog}
        handleDialogClose={() => setOpenPremiumDialog(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '350px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-received-from-and-transferred-to" />
      </TMDialog>
      <TMDialog
        key="edit-release"
        title={translate('carrierui.editMultiple.ctr')}
        dialogOpen={openEdit}
        handleDialogClose={() => setOpenEdit(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <SRPForm
          object={state.editFormData}
          handleChange={handleChange}
          handleSubmit={handleEditRelease}
          submitLabel={translate('carrierui.editMultiple.ctr.label')}
          handleCancel={() => setOpenEdit(false)}
          cancelLabel={translate("general.cancel")}
          fields={getFormFields(terminals, cmaAgents, translate, 'multiple')}
          needsConfirm={true}
          enableSubmitCondition={submitEditLabel}
          confirmMsg={translate(
            'carrierui.createRelease.section.bl.helperText'
          )}
        />
      </TMDialog>
    </React.Fragment>

  )
}

export default withRouter(ReleaseOverview)


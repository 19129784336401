import React, { useState, useReducer, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import './style.css';
import SRPForm from './SRPForm';
import useCarrierApiError from './carrier-ui/hooks/useCarrierApiError';
import client from '../feathers';
import useIntl from '../hooks/useIntl';
import useAuth from '../hooks/useAuth';
import useWalletError from '../hooks/useWalletError';
import { useTerminals } from '../hooks/useTerminals';
import { useCmaAgents } from './carrier-ui/hooks/useCmaAgents';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import getPincode from '../util/pincode';
import { formatDate } from '../util/date';
import { validateRelease, createApiReleaseObjectFromObject, getFormFields } from '../util/release';
import { isTokenValid } from '../util/token';
import { useHistory } from 'react-router-dom';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { findOrgName } from '../util/connections';
import GreenLightsPopover from './GreenLightsPopover';
import useSentry from '../hooks/useSentry';

const moment = require('moment');

const Containers = ({
  containers: releasesArray,
  refreshBills,
  onContainersSelect,
  selectedReleaseAddresses = [],
  showPincode = false,
  handleDialogClose,
  selectableRows = 'multiple',
  connections,
  connectedOrganizations
}) => {

  const [openEdit, setOpenEdit] = useState(false);
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [message, setMessage] = useState('Loading...');
  const [containers, setContainers] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { translate } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { user } = useAuth();
  const [terminals] = useTerminals();
  const [cmaAgents] = useCmaAgents();
  const onWalletError = useWalletError();
  const onCarrierApiError = useCarrierApiError();
  // determine the last column of the table by checking if we're in a dialog or not
  const showPincodeCol = handleDialogClose !== undefined;
  const isCarrierUser = user.organizationRole === 'carrier';
  const history = useHistory();
  const logSentry = useSentry();

  const RELEASE_ADDRESS_INDEX = 13;

  const openEditRelease = async (release) => {
    dispatch({
      payload: {
        ...release,
        validFrom: moment(release.validFrom).format('DD/MM/YYYY'),
        validUntil: moment(release.validUntil).format('DD/MM/YYYY'),
      },
    });
    setOpenEdit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ payload: { [name]: value } });
  };

  const handleEditRelease = async () => {
    if (!isCarrierUser) {
      return;
    }

    showProgressDialog();

    try {
      const release = createApiReleaseObjectFromObject(state);
      validateRelease(release);
      await client.service('releases').update(state.releaseAddress, release);
      // close the progress dialog
      hideProgressDialog();
      // close the dialog
      setOpenEdit(false);
      // reset the form
      dispatch({ type: 'RESET' });
      // refresh the list of bills and reload these containers
      refreshBills();
    } catch (error) {
      // close the progress dialog
      hideProgressDialog();
      // handle the error
      logSentry(error);
      onCarrierApiError(error);
    }
  };

  const containersHeaders = () =>
    [
      'releaseStatus',
      'containerNumber',
      'releasedOn',
      'validFrom',
      'validUntil',
      'pickupPort',
      'pickupLocation',
      'turnInLocation',
      'turnInReference',
      {
        name: translate('releaseOverview.containers.columns.receivedFrom'),
        options: { 
          filter: user.features.canSeeTransferInfo && !isCarrierUser, 
          download: user.features.canSeeTransferInfo && !isCarrierUser, 
          display: !isCarrierUser, 
          viewColumns: !isCarrierUser 
        }
      },
      'transferStatus',
      { name: "Transferred to" },
      showPincodeCol ? 'pincode' : isCarrierUser ? 'action' : undefined,
    ]
      .filter((c) => c)
      .map((c) => typeof c === 'string' ? translate(`releaseOverview.containers.columns.${c}`) : c);

  useEffect(() => {
    const fetchContainers = async () => {
      let pincodeResults = [];
      if (showPincode) {
        // We're going to use the wallet here to get the pincode, so check the token already here
        if (!isTokenValid()) {
          onWalletError(new Error(translate('session.expired')));
          history.push('/');
          return;
        }

        enqueueSnackbar(
          <div>
            {translate('pincode.retrieve.pins.progress')}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </div>,
          { persist: true }
        );

        let getPincodesForReleases = releasesArray.map(release => release.releaseAddress);

        if (selectedReleaseAddresses.length > 0) {
          getPincodesForReleases = releasesArray
            // don't filter on owner yet, let the chain return "must be owner" error
            // otherwise the release will not be included in the final report
            .filter(release => selectedReleaseAddresses.includes(release.releaseAddress) )
            .map(({address, version}) => ({address, version}));
        }
        try {
          pincodeResults = await getPincode(getPincodesForReleases, user.organization);
        } catch (e) {
          showPincode && closeSnackbar();
          handleDialogClose && handleDialogClose();
          onWalletError(e);
        }
      }

      try {
        let _containers = await Promise.all(
          releasesArray?.map(async release => {
            let pincodeResult = pincodeResults.find(res => res.address === release.releaseAddress);
            if (pincodeResult?.pincode) {
              // SCRDEV-127: Set the right status after the pin is retrieved.
              // We could re-fetch the container here, but that's already done when closing the dialog.
              // So let's set it manually, purely for display purposes
              if (release.transferStatus === "owned") {
                release.transferStatus = "pinRetrieved";
              }
              pincodeResult = pincodeResult.pincode;
            } else {
              pincodeResult = pincodeResult ? pincodeResult.error : undefined;
            }

            let statusWithLights = translate(`releaseStatus.${release.releaseStatus}`);

            if(user.features.canGreenLights) {
             statusWithLights = <GreenLightsPopover data={release.greenLights} text={translate(`releaseStatus.${release.releaseStatus}`)}/>
            }

            // user.features.canSeeTransferInfo is also checked in the column array
            let receivedFrom = <Chip label="Go Premium" color="primary" size="small" clickable onClick={() => setOpenPremiumDialog(true)}/>
            if (user.features.canSeeTransferInfo) {
              if (release.prevParty) {
                if (release.prevParty.startsWith('address:')) {
                  receivedFrom = findOrgName(release.prevParty.replace('address:', ''), connections, connectedOrganizations)
                } else {
                  receivedFrom = release.prevParty;
                }
              } else {
                receivedFrom = translate('release.receivedFrom.unknown');
              }
            }
            
            let transferStatus = translate(
              release.transferStatus === 'assigned' ? `transferStatus.assigned.info` : `transferStatus.${release.transferStatus}`, 
              {
                type: release.assignedDriverName ? translate('driver.title') : translate('barge.title'),  // in case of 'assigned'
                name: release.assignedDriverName ? release.assignedDriverName : release.assignedBargeName // in case of 'assigned'
              }
            );

            let nextParty = "";
            if (release.transferStatus === "transferred") {
              nextParty = <Chip label="Go Premium" color="primary" size="small" clickable onClick={() => setOpenPremiumDialog(true)}/>
            
              if (user.features.canSeeTransferInfo) { 
                if (release.nextParty) {
                  if (release.nextParty.startsWith('address:')) {
                    nextParty = findOrgName(release.nextParty.replace('address:', ''), connections, connectedOrganizations)
                  } else {
                    nextParty = release.nextParty;
                  }
                } else {
                  nextParty = translate('transferStatus.toNextParty');
                }
              }

              // check if nextParty is actually filled in or not
              if (!nextParty) {
                nextParty = translate('transferStatus.toNextParty');
              }
            }
            
            return [
              statusWithLights,
              release.containerNumber,
              formatDate(release.createdAt),
              translate('releaseOverview.containers.discharge'),
              formatDate(release.validUntil),
              [undefined, null].includes(release.edi_terminal) ? '' : ([undefined, null].includes(release.edi_terminal.edi_port) ? '' : release.edi_terminal.edi_port.locode),
              release.pickupLocation,
              release.turnInLocation,
              release.turnInReference,
              receivedFrom,
              transferStatus,
              nextParty,
              showPincodeCol ? (
                pincodeResult
              ) : isCarrierUser ? (
                <EditIcon
                  style={{ color: '#666' }}
                  onClick={() => openEditRelease(release)}
                />
              ) : (
                <span />
              ),
              release.releaseAddress /* RELEASE_ADDRESS_INDEX */,
            ];
          })
        );

        
        setContainers(_containers);
        setMessage(translate('releaseOverview.containers.noContainersFound'));
        showPincode && closeSnackbar();
      } catch (e) {
        showPincode && closeSnackbar();
        handleDialogClose && handleDialogClose();
        onWalletError(e);
      }
    };

    fetchContainers();
  }, [releasesArray]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="containers-table">
        <TMDataTable
          title={translate('releaseOverview.containers.title')}
          columns={containersHeaders()}
          data={containers}
          options={{
            pagination: false,
            filter: true,
            print: false,
            fixedHeader: false,
            download: false,
            customToolbar: null,
            selectableRows,
            selectableRowsOnClick: selectableRows !== 'none',
            textLabels: {
              body: {
                noMatch: message,
              },
            },
            onSelectionChange: (selected) => {
              onContainersSelect &&
                onContainersSelect(
                  selected.map((release) => ({
                    releaseAddress: release[RELEASE_ADDRESS_INDEX],
                  }))
                );
            },
          }}
        />
      </div>

      <TMDialog
        key="edit-release"
        title={translate('carrierui.edit')}
        dialogOpen={openEdit}
        handleDialogClose={() => setOpenEdit(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <SRPForm
          object={state}
          needsConfirm={true}
          confirmMsg={translate(
            'carrierui.createRelease.section.bl.helperText'
          )}
          handleChange={handleChange}
          handleSubmit={handleEditRelease}
          submitLabel={translate('carrierui.edit')}
          handleCancel={() => setOpenEdit(false)}
          cancelLabel={translate("general.cancel")}
          fields={getFormFields(terminals, cmaAgents, translate)}
        />
      </TMDialog>

      <TMDialog
        key="premium-transfer-info"
        title={translate('premium.transferinfo.title')}
        dialogOpen={openPremiumDialog}
        handleDialogClose={() => setOpenPremiumDialog(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '350px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-received-from-and-transferred-to" />
      </TMDialog>
    </div>
  );
};

const initialState = {
  blNumber: '',
  portOfLoading: '',
  portOfDestination: '',
  vessel: '',
  agent: '',
  stayNumber: '',
  lloydsNumber: '',
  voyageNumber: '',
  containerNumber: '',
  isoTypeCode: '',
  pickupLocation: '',
  turnInLocation: '',
  validFrom: '',
  validUntil: '',
  turnInReference: '',
  releaseAddress: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState };
    default:
      // = SET
      return { ...state, ...payload };
  }
};

export default Containers;

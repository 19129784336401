import React, { useState, useReducer, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import XLSX from 'xlsx';
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select';
import CreateIcon from '@material-ui/icons/AddBox'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useCarrierApiError from './hooks/useCarrierApiError'
import BatchUploadReport from './BatchUploadReport'
import SRPForm from '../SRPForm';
import OrganizationSelectionTable from '../OrganizationSelectionTable'
import client from '../../feathers';
import useIntl from '../../hooks/useIntl'
import { useTerminals } from '../../hooks/useTerminals';
import { useCmaAgents } from './hooks/useCmaAgents';
import TMMenu from '../../lib/AppBar/TMMenu.jsx';
import TMDialog from '../../lib/Dialog/TMDialog';
import { useProgressDialog } from '../../context/ProgressDialogProvider'
import { useAlertDialog } from '../../context/AlertDialogProvider'
import { getConnectedOrganizations } from '../../util/connections'
import { validateRelease, createApiReleaseObjectFromArray, createApiReleaseObjectFromObject, getFormFields } from '../../util/release';
import useSentry from '../../hooks/useSentry';

const moment = require('moment');

export const CreateReleaseButton = ({ classes, user, refreshBills, doTransfer }) => {

  const [open, setOpen] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState)

  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { translate } = useIntl();
  const [terminals] = useTerminals();
  const [cmaAgents] = useCmaAgents();
  const onCarrierApiError = useCarrierApiError();
  const logSentry = useSentry();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openForm = (formType) => {
    setOpen(formType);
    setAnchorEl(null)
  }

  // set agent on load
  useEffect(() => {
    dispatch({ payload: { agent: 'CMA/CGM Belgium' } })
  }, []);

  // set connected organizations on load
  useEffect(() => {
    async function fetchData() {
      const connections = await getConnectedOrganizations();
      dispatch({ 
        payload: {
          connectedOrganizations: connections.connectedOrganizations
        }
      })     
    }
    fetchData();
  }, [])

  // used for upload dialog 
  const onOrganizationSelect = selectedOrganization => dispatch({ payload: { selectedOrganization } })

  const handleChange = (e) => {
    const { name, value, files } = e.target
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, {cellDates: true, cellText: false, type: 'array'});
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        var releases = XLSX.utils.sheet_to_json(first_worksheet, {header:1, blankrows:false});
        // skip the header row
        releases.shift()        
        enqueueSnackbar(translate('fileUpload.complete', { numberOfReleases: releases.length || 0 }), { autoHideDuration: 3000 })
        dispatch({ payload: { excelRows: releases } })
      };
      reader.readAsArrayBuffer(files[0]);
    }

    dispatch({ payload: { [name]: value } })

  };

  const handleAddRelease = async () => {
    try {
      if (open === 'multi') {
        enqueueSnackbar(
          <div>
            {translate("carrierui.multi.progress")}
            <span className="dot">.</span><span className="dot">.</span><span className="dot">.</span> 
            <span style={{marginLeft: '10px'}} id='upload-progress'></span>
          </div>, 
          { persist: true }
        )

        let validationResults = [];
        let createResults = [];
        let transferResults;

        validationResults = state.excelRows.map((row, idx) => {
          try {
            if (document.querySelector("#upload-progress")) {
              document.querySelector("#upload-progress").innerHTML = (idx+1)+'/'+state.excelRows.length;
            }
            const release = createApiReleaseObjectFromArray(row, state.agent, user);
            validateRelease(release, terminals);
            return release;
          } catch(error) {
  	    // keep same structure as a createResult
            return {
	            billOfLading: { blNumber: row[0] },
              container: { containerNumber: row[7] },
              errorText: error.message.startsWith('carrierui.') ? translate(error.message) : error.message
            };  
          }
        })

        if (validationResults.filter(r => !r.errorText).length > 0) {
          // at least one release passed validation, continue to create
          createResults = await client.service('releases').create(validationResults.filter(r => !r.errorText));

          if (state.selectedOrganization && createResults.find(res => res.statusText)) {
            // at least one release was created successfully, continue to transfer
            closeSnackbar();
            enqueueSnackbar(
              <div>
                Start transfer
                <span className="dot">.</span><span className="dot">.</span><span className="dot">.</span> 
              </div>, 
              { persist: true }
            )
            const createSuccesses = createResults.filter(res => res.statusText).map(res => res.data.map(r => r.address)).flat();
            transferResults = await doTransfer('transfer', state.selectedOrganization, createSuccesses, true)
            // sometimes doTransfer just returns "{ error: 'Error' }"
            if (!transferResults.hasOwnProperty('eligible_releases') && transferResults.hasOwnProperty('error')) {
                transferResults = {
                    eligible_releases: [],
                    errors: createSuccesses.map(cs => ({address: cs, error: ', but transfer failed'}))
                }
            }  
            // console.log("transferResults", transferResults);
          }
        }
        closeSnackbar();
        // handle results
        showAlert(
          translate("carrierui.addRelease.success.multi"), 
          <BatchUploadReport data={{validationResults, createResults, transferResults}} />,
          'md'
          );
        // close the progress dialog
        hideProgressDialog();
        // close the dialog
        setOpen(undefined);
        // reset the form
        dispatch({ type: 'RESET' });
        // refresh the list of bills
        refreshBills();
      } else {
        // make a copy, since the state is reset in the finally clause meanwhile
        const release = createApiReleaseObjectFromObject({ ...state }, user)
        showProgressDialog();
        let doCreate = false;
        try {
          validateRelease(release, terminals);
          doCreate = true;
        } catch (error) {
          // no need to abort the whole operation on a validation error, 
          // just show it and let the user carry on to correct it
          onCarrierApiError(error)
          // close the progress dialog
          hideProgressDialog()
        }
        if (doCreate) {
          let result = await client.service('releases').create(release)

          enqueueSnackbar(translate("carrierui.addRelease.success.single"), { autoHideDuration: 1500 })
          // close the progress dialog
          hideProgressDialog()
          // close the dialog
          setOpen(undefined)
          // reset the form
          dispatch({ type: 'RESET' })
          // refresh the list of bills
          refreshBills()
        }
      }
    } catch (error) {
      // close the progress dialog
      hideProgressDialog()
      // close the snackbar
      closeSnackbar()
      // handle the error
      logSentry(error)
      onCarrierApiError(error)
    } 
  }

	return (
    <React.Fragment>
      <ClickAwayListener onClickAway={() => { setAnchorEl(null) }}>
        <React.Fragment>
          <Tooltip title={translate("dataTable.controls.createRelease")}>
            <IconButton aria-label="create release" onClick={handleMenuOpen} className={classes.icon_hover}>
                <CreateIcon />
            </IconButton>
          </Tooltip>
          <TMMenu
            id='create-release-menu'
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}>
            <MenuItem onClick={() => openForm('multi')}>{translate("carrierui.createReleaseMenu.multi")}</MenuItem>
            <MenuItem onClick={() => openForm('single')}>{translate("carrierui.createReleaseMenu.single")}</MenuItem>
          </TMMenu>
        </React.Fragment>
      </ClickAwayListener>

      <TMDialog
        key="add-multi"
        title={translate("carrierui.dialog.add.multi.title")}
        dialogOpen={open === 'multi'}
        handleDialogClose={() => setOpen(undefined)}
        maxWidth="md"
        showBottomClose={false}
      >
      	<React.Fragment>
          <SRPForm
            object={state}
            handleChange={handleChange}
            handleSubmit={handleAddRelease}
            submitLabel={translate("carrierui.dialog.add.multi.title")}
            handleCancel={() => setOpen(undefined)}
            cancelLabel={translate("general.cancel")}
            fields={[ 
              { field: 'excelName', label: 'Excel file', required: true, type: 'file', accept: 'csv, xls, xlsx', variant: 'filled' }, 
              { field: 'agent', label: translate('releaseOverview.columns.agent'), componentType: Select, suggestions: cmaAgents },
              { field: 'selectedOrganization', label: 'Next Release Party', 
                componentType: OrganizationSelectionTable, suggestions: state.connectedOrganizations, handleChange: onOrganizationSelect, fullWidth: true,
                helperText: translate(`transferRevoke.transfer.dialog.line_2`) }, 
            ]}
          />    
        </React.Fragment>
      </TMDialog>

      <TMDialog
        key="add-single"
        title={translate("carrierui.dialog.add.single.title")}
        dialogOpen={open === 'single'}
        handleDialogClose={() => setOpen(undefined)}
        maxWidth="md"
        showBottomClose={false}
      >
        <SRPForm
          object={state}
          handleChange={handleChange}
          handleSubmit={handleAddRelease}
          submitLabel={translate("carrierui.dialog.add.single.title")}
          handleCancel={() => setOpen(undefined)}
          cancelLabel={translate("general.cancel")}
          fields={getFormFields(terminals, cmaAgents, translate)}
        />
      </TMDialog>
    </React.Fragment>
  )
}

const initialState = {
  // Helper data
  excelName: '',
  excelRows: [],
  selectedOrganization: null,
  connectedOrganizations: [],
  // Release fields
  blNumber: '',
  portOfLoading: '',
  portOfDestination: '',
  vessel: '',
  agent: '',
  stayNumber: '',
  lloydsNumber: '',
  voyageNumber: '',
  containerNumber: '',
  isoTypeCode: '',
  pickupLocation: '',
  turnInLocation: '',
  validFrom: moment().format('DD/MM/YYYY'),
  validUntil: moment().add(30, 'days').format('DD/MM/YYYY'),
  turnInReference: '',
}

const reducer = (state, { type, payload }) => {

  switch (type) {
    case 'RESET':
      return { ...initialState, agent: state.agent, connectedOrganizations: state.connectedOrganizations }
    default:
      // = SET
      return { ...state, ...payload }
  }
}

import React, { useState } from 'react';

export const useCmaAgents = () => {
  const [state, setState] = useState([
    { 
      value: 'CMA/CGM Belgium', 
      label: 'CMA/CGM Belgium', 
      target: { 
        name: 'agent', 
        value: 'CMA/CGM Belgium'
      }
    },
    { 
      value: 'APL', 
      label: 'APL', 
      target: { 
        name: 'agent', 
        value: 'APL'
      }
    }
  ]);

  return [state];
};